<template>
  <div class="p-3">
    <appCheckDriversLicenceTrafficOffenceView
      :propsData1="getUserFromId"
      :propsData2="getDriversOffences"
    ></appCheckDriversLicenceTrafficOffenceView>
  </div>
</template>
<script>
import appCheckDriversLicenceTrafficOffenceView from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffenceView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "CheckRightToWorkInAustraliaView",
  components: {
    appCheckDriversLicenceTrafficOffenceView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getDriversOffences", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchDriversOffences", "fetchUserFromId"]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchDriversOffences(this.projectId);
  },
};
</script>

